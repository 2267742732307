export default [
  {
    key: 'id',
    label: 'field.id',
    width: 2000,
    secondary: true,
  },
  {
    key: 'outletCode',
    label: 'field.outletCode',
    width: 3500,
  },
  {
    key: 'regionCode',
    label: 'field.region',
    width: 2500,
  },
  {
    key: 'agentCode',
    label: 'field.agent',
    width: 2500,
  },
  {
    key: 'name',
    label: 'field.outletName',
    localization: true,
    width: 6000,
  },
  {
    key: 'ownerName',
    label: 'field.ownerName',
    width: 6000,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 2500,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    width: 6000,
  },
  {
    key: 'contactNumber',
    label: 'field.contactNumber',
    width: 4000,
  },
  {
    key: 'outletSegmentName',
    label: 'field.outletSegment',
    localization: true,
    width: 6000,
  },
  {
    key: 'address',
    label: 'field.address',
    localization: true,
    width: 10000,
  },
  {
    key: 'latitude',
    label: 'field.latitude',
    width: 4000,
  },
  {
    key: 'longitude',
    label: 'field.longitude',
    width: 4000,
  },
  {
    key: 'customerPriceGroup',
    label: 'field.priceGroup',
    width: 4000,
  },
  {
    key: 'registeredAt',
    label: 'field.firstLoginDate',
    width: 4000,
  },
  {
    key: 'callPlanConfigId',
    label: 'field.callPlanDate',
    width: 2500,
  },
  {
    key: 'isEnable',
    label: 'field.status',
    width: 2000,
  },
  {
    key: 'updatedAt',
    label: 'field.updatedDate',
    width: 4000,
  },
];
