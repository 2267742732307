export default [
  {
    key: 'increasement',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'region',
    label: 'field.region',
    hideToggle: true,
  },
  {
    key: 'agent',
    label: 'field.agent',
    hideToggle: true,
  },
  {
    key: 'image',
    label: 'field.image',
    type: 'image',
  },
  {
    key: 'outletCode',
    sortable: true,
    sortField: 'outletCode',
    label: 'field.outletCode',
    stickyColumn: true,
    variant: 'light',
    hideToggle: true,
  },
  {
    key: 'name',
    label: 'field.outletName',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'ownerName',
    label: 'field.ownerName',
    hideToggle: true,
  },
  {
    key: 'salesperson',
    label: 'field.salesExecutive',
    hideToggle: true,
  },
  {
    key: 'contactNumber',
    label: 'field.contactNumber',
  },
  {
    key: 'outletSegment',
    label: 'field.outletSegment',
  },
  {
    key: 'customerPriceGroup',
    label: 'field.priceGroup',
  },
  {
    key: 'address',
    label: 'field.address',
    localization: true,
  },
  {
    key: 'latitude',
    label: 'field.latitude',
  },
  {
    key: 'longitude',
    label: 'field.longitude',
  },
  {
    key: 'registeredAt',
    label: 'field.firstLoginDate',
  },
  {
    key: 'username',
    label: 'field.username',
  },
  {
    key: 'callPlanConfig',
    label: 'field.callPlanDate',
    hideToggle: true,
  },
  {
    key: 'setupAccount',
    label: 'field.setupAccount',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updatedDate',
    hideToggle: true,
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
