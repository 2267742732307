<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <sync-button
                  :resource="resource"
                  :repository="OutletRepository"
                  :module="$t('field.outlet')"
                />
              </b-col>
              <b-col class="pl-0" cols="auto" v-if="$can('import', resource)">
                <import-excel-button
                  template="/import-callplan-template.xlsx"
                  :tableFields="importFields"
                  :resource="resource"
                  :repository="OutletRepository"
                />
              </b-col>
              <b-col class="pl-0" cols="auto">
                <export-excel-button
                  :resource="resource"
                  :query="query"
                  :params="params"
                  :loading="loading"
                  :repository="OutletRepository"
                  :fields="exportFields"
                  :search-fields="searchFields"
                  :file-name="$t('breadcrumb.outlet')"
                  :selected="selected"
                />
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :visible-columns="visibleColumns"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
          :selectable="true"
          v-model="selected"
        >
          <template #cell(outletSegment)="data">
            <span v-if="data.item.outletSegment">
              {{ trans(data.item.outletSegment, "name", $i18n.locale) }}
            </span>
          </template>
          <template #cell(salesperson)="data">
            <span v-if="data.item.salesperson">
              {{ data.item.salesperson.name }}
              <small class="d-block text-primary">
                {{ data.item.salesperson.idCard }}
              </small>
            </span>
          </template>
          <template #cell(callPlanConfig)="data">
            <b-link
              @click="showSetCallPlanModal(data.item)"
              class="link-underline"
              v-if="data.item.callPlanConfig"
            >
              {{ data.item.callPlanConfig.code }}
            </b-link>
            <b-link
              @click="showSetCallPlanModal(data.item)"
              class="link-underline text-secondary"
              v-else
            >
              {{ $t("button.set") }}
            </b-link>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`field.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`field.inactive`) }}
            </b-badge>
          </template>
          <template #cell(setupAccount)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.username"
            >
              {{ $t(`field.ready`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`field.notYet`) }}
            </b-badge>
          </template>
          <template #cell(setupBankAccount)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isSetUpOnlinePayment"
            >
              {{ $t(`field.ready`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`field.notYet`) }}
            </b-badge>
          </template>
          <template #cell(lastActivityAt)="data">
            {{ data.item.lastActivityAt | formatDate }}
          </template>

          <template #back-button="{ item }">
            <b-button
              @click="showSetAccountModal(item)"
              v-b-tooltip.hover
              :title="$t('field.setupAccount')"
              :disabled="!$can('update', resource)"
              :variant="!$can('update', resource) ? 'secondary' : 'primary'"
              pill
              size="sm"
            >
              <feather-icon icon="LockIcon" />
            </b-button>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>

    <set-call-plan-modal ref="setCallPlanModal" @save="onSaveNewCallPlan" />
    <set-account-modal ref="setAccountModal" @save="onSaveNewCallPlan" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BBadge,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import ExportExcelFields from "./exportExcelFields";
import SearchInputs from "./searchInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NColumnVisibility from "@/components/NColumnVisibility";
import ExportExcelButton from "@/components/ExportExcelButton";
import SetCallPlanModal from "./setCallPlanModal/Index.vue";
import SetAccountModal from "./setAccountModal/Index.vue";
import SyncButton from "@/components/sync/SyncButton.vue";
import ImportExcelButton from "@/components/ImportExcelButton.vue";
import ImportFields from "./importFields";

const OutletRepository = Repository.get("outlet");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BBadge,
    BLink,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NAsyncSingleSelect,
    vSelect,
    NColumnVisibility,
    ExportExcelButton,
    SetCallPlanModal,
    SetAccountModal,
    SyncButton,
    ImportExcelButton,
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.outlet;
      },
      set(value) {
        this.$store.commit("column/SET_OUTLET_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        callPlanConfigId: this.$route.query.callPlanConfigId || [],
        regionId: this.$route.query.regionId || [],
        agentId: this.$route.query.agentId || [],
        salespersonId: this.$route.query.salespersonId || [],
        outletSegmentId: this.$route.query.outletSegmentId || [],
        channel: this.$route.query.channel || [],
        outletCode: this.$route.query.outletCode || null,
        ownerName: this.$route.query.ownerName || null,
        nameEn: this.$route.query.nameEn || null,
        nameKm: this.$route.query.nameKm || null,
        outletCode: this.$route.query.outletCode || null,
        isEnable: this.$route.query.isEnable || null,
        updatedAt: this.$route.query.updatedAt || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        callPlanConfigId: this.$route.query.callPlanConfigId || [],
        regionId: this.$route.query.regionId || [],
        agentId: this.$route.query.agentId || [],
        salespersonId: this.$route.query.salespersonId || [],
        outletSegmentId: this.$route.query.outletSegmentId || [],
        channel: this.$route.query.channel || [],
        outletCode: this.$route.query.outletCode || null,
        ownerName: this.$route.query.ownerName || null,
        nameEn: this.$route.query.nameEn || null,
        nameKm: this.$route.query.nameKm || null,
        outletCode: this.$route.query.outletCode || null,
        isEnable: this.$route.query.isEnable || null,
        updatedAt: this.$route.query.updatedAt || [],
      },
      selected: [],
      OutletRepository,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    showSetCallPlanModal(item) {
      this.$refs.setCallPlanModal.show(item);
    },
    showSetAccountModal(item) {
      this.$refs.setAccountModal.show(item);
    },
    onSaveNewCallPlan(item) {
      const index = this.items.findIndex((element) => {
        return element.id == item.id;
      });
      if (index != -1) {
        this.items[index] = { ...item };
        this.key++;
      }
    },
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.search();
    },
    getData() {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();

      OutletRepository.index({
        ...this.query,
        searchFields: this.searchFields,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const exportFields = ExportExcelFields;
    const importFields = ImportFields;
    const searchFields = SearchInputs;
    const resource = "outlet";
    const route = "outlet";

    return {
      fields,
      exportFields,
      importFields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
