export default [
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'tel',
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'confirmed:password',
    type: 'password',
    hideInView: true,
  },
]
