export default [
  {
    key: 'callPlanConfigId',
    label: 'field.callPlanDate',
    rules: '',
    type: 'async-single-selection',
    repository: 'callPlanConfig',
    selectionKey: 'id',
    selectionLabel: 'code',
    clearable: true,
    limit: 100,
    cols: 12,
  },
]
