export default [
  {
    key: "increasement",
    label: "#",
    thClass: "w-50px text-center",
    tdClass: "text-center",
  },
  {
    key: "outletCode",
    label: "field.outletCode",
    hideToggle: true,
  },
  {
    key: "callPlanConfigId",
    label: "field.callPlan",
  },
  // {
  //   key: "status",
  //   label: "field.status",
  // },
];
