<template>
  <div v-if="$can('import', resource)">
    <b-button
      :disabled="!$can('import', resource) || loading"
      :variant="!$can('import', resource) ? 'secondary' : 'success'"
      @click="showImportModal()"
    >
      {{ $t("button.importExcel") }}
    </b-button>

    <b-modal
      v-model="importModel"
      cancel-variant="outline-secondary"
      ok-variant="success"
      :cancel-title="$t('button.close')"
      size="lg"
      :title="$t('button.preview')"
      centered
      @ok="readFile"
    >
      <validation-observer ref="importForm">
        <n-input :fields="fields" v-model="data" :key="`import-${key}`">
          <template #file>
            <b-form-group :label="$t('field.excel')">
              <validation-provider
                #default="{ errors }"
                vid="excel"
                name="field.excel"
                :rules="`required`"
              >
                <b-form-file
                  name="excel"
                  :state="errors.length > 0 ? false : null"
                  v-model="data.file"
                  :browse-text="$t('button.browse')"
                  :placeholder="$t('button.chooseFileOrDropHere')"
                  :drop-placeholder="$t('button.dropHere')"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ></b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
                <b-row class="pt-1" v-if="template">
                  <b-col class="text-right">
                    <b-link class="link-underline" :href="template">
                      {{ $t("button.downloadTemplate") }}
                    </b-link>
                  </b-col>
                </b-row>
              </validation-provider>
            </b-form-group>
          </template>
        </n-input>
      </validation-observer>

      <template #modal-ok>
        <b-spinner small label="Spinning" v-if="loading"></b-spinner>
        <span v-else>{{ $t("button.preview") }}</span>
      </template>
    </b-modal>

    <b-modal
      v-model="previewModel"
      cancel-variant="outline-secondary"
      ok-variant="success"
      :cancel-title="$t('button.close')"
      size="xl"
      :title="$t('button.preview')"
      centered
      @ok="importExcel"
      @hide="checkIsCanClose"
    >
      <b-table-simple
        class="list-table"
        :stickyHeader="true"
        :responsive="true"
        :bordered="false"
        :borderless="true"
        :striped="true"
      >
        <thead>
          <b-tr>
            <b-th
              v-for="header in tableFields"
              :key="`header-${header.key}`"
              :class="header.thClass"
            >
              {{ $t(header.label) }}
            </b-th>
          </b-tr>
        </thead>
        <tbody>
          <b-tr v-for="(item, index) in items" :key="`row-${index}`">
            <b-td
              v-for="header in tableFields"
              :key="`cell-${index}-${header.key}`"
              :class="header.tdClass"
            >
              {{ header.key === "increasement" ? index + 1 : item[header.key] }}
            </b-td>
          </b-tr>
        </tbody>
      </b-table-simple>

      <div class="text-right">
        <small class="my-4">
          {{ $t("field.total") }} : {{ total | number }}
        </small>
      </div>

      <template #modal-ok>
        <b-spinner small label="Spinning" v-if="loading"></b-spinner>
        <span v-else>{{ $t("button.import") }}</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
  BLink,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BSpinner,
} from "bootstrap-vue";
import readXlsxFile from "read-excel-file";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import NTable from "@/components/NTable";

const map = {
  outletCode: "outletCode",
  callPlanConfigId: "callPlanConfigId",
};

export default {
  props: {
    resource: {
      type: String,
      default: "",
    },
    template: {
      type: String,
      default: "",
    },
    tableFields: {
      default: () => [],
    },
    repository: {},
  },
  components: {
    BButton,
    BFormGroup,
    BFormFile,
    BLink,
    BRow,
    BCol,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BSpinner,

    NFormConfirmation,
    NInput,
    NTable,
  },
  data() {
    return {
      importModel: false,
      key: 1,
      data: {
        file: null,
      },
      fields: [
        {
          key: "file",
          cols: 12,
        },
      ],

      previewModel: false,

      loading: false,

      filelist: [],
      items: [],
      total: 0,
    };
  },
  methods: {
    checkIsCanClose(bvModalEvent) {
      if (this.loading) {
        bvModalEvent.preventDefault();
      }
    },

    showImportModal() {
      this.data.file = null;
      this.page = 1;
      this.limit = 1000;
      this.importModel = true;
    },

    readFile(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.importForm.validate().then((success) => {
        if (success) {
          this.readExcel();
        }
      });
    },

    readExcel() {
      this.loading = true;
      setTimeout(() => {
        var reader = new FileReader();
        reader.onload = (e) => {
          readXlsxFile(this.data.file, { map }).then((result) => {
            if (result.rows) {
              this.items = [...result.rows];
              this.total = result.rows.length;
            } else {
              this.items = [];
              this.total = 0;
            }
            this.loading = false;
            this.previewModel = true;
          });
        };
        reader.readAsDataURL(this.data.file);
      }, 50);
    },

    importExcel() {
      //TODO: import to server
      if (this.repository) {
        this.loading = true;
        this.repository
          .import({
            lines: this.items,
          })
          .then((response) => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
