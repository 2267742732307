export default [
  {
    key: 'regionId',
    label: 'field.region',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
  },
  {
    key: 'agentId',
    label: 'field.agent',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'agent',
    selectionKey: 'id',
    selectionLabel: 'agentCode',
  },
  {
    key: 'salespersonId',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
  },
  {
    key: 'ownerName',
    label: 'field.ownerName',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    type: 'text',
    operator: '%',
  },
  {
    key: 'outletCode',
    label: 'field.outletCode',
    type: 'text',
    operator: '%',
  },
  {
    key: 'outletSegmentId',
    label: 'field.outletSegment',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'outletSegment',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'callPlanConfigId',
    label: 'field.callPlanDate',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'callPlanConfig',
    selectionKey: 'id',
    selectionLabel: 'code',
    limit: 100,
  },
  {
    key: 'channel',
    label: 'field.channel',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'field.onPremise', value: '1' },
      { text: 'field.offPremise', value: '2' },
    ],
  },
  {
    key: 'isEnable',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.active', value: '1' },
      { text: 'field.inactive', value: '0' },
    ],
  },
  {
    key: 'registeredAt',
    label: 'field.firstLoginDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
  {
    key: 'updatedAt',
    label: 'field.updatedDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
];